<template>
  <div class="mt-2">
    <el-row :gutter="20">
      <el-col :span="7"> {{ title }} </el-col>
      <el-col :span="12">
        <div class="form-group">
          <el-select v-model="field.question_type">
            <el-option :value="'YES_OR_NO'" label="Yes Or No"> </el-option>
            <el-option :value="'MCQ'" label="MCQ"> </el-option>
            <el-option :value="'TEXT'" label="TEXT"> </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ["field", "title"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
</style>